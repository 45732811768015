<template>
    <div class="shop_main">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">关于我们</span>
        </div>

        <img :src="'./img/banner_about.png'" class="page_banner" />
        <div class="shop_about pt30">
            <div class="about_tab">
                <div class="item" :class="{on: type === 1}" @click="type = 1">简介</div>
                <div class="item" :class="{on: type === 2}" @click="type = 2">文化</div>
                <div class="item" :class="{on: type === 3}" @click="type = 3">资质</div>
                <div class="item" :class="{on: type === 4}" @click="type = 4">联系</div>
            </div>

            <div v-show="type === 1">
                <h2 class="subTitle">公司简介</h2>
                <img :src="'./img/about_01.jpg'" class="titleIcon" />

                <div class="companyDesc">
                    <img :src="'./img/about_02.jpg'" style="float: left; margin-right: 30px" />
                    <p style="text-indent: 2em">易诚药材通隶属于易诚众联网络科技有限公司，是集中药材交易、土特产品、农副产品、中药材供求商机等服务于一体的一站式集采销售平台。重点服务于全国中药材行业市场的药商，致力于打造行业内最具影响力的重度垂直电商平台，为中药材电子商务的应用及中药材产业信息化的发展做好一站式电商交易服务。</p>
                    <p style="text-indent: 2em">易诚药材通整合了交易过程中的信息流、商流、资金流、物流、发票流，实现五流合一、统一管理，并以此为基础为平台上下游企业提供完善的供应链服务。最终依托平台势能，不断拓展延伸产业链条，实现对中药材行业的产业覆盖。</p>
                    <p style="margin-top: 30px">易诚药材通对于中药材商家更直观的帮助在于以下几点：</p>
                    <p style="text-indent: 2em">第一、客户了解药材种类更加丰富；</p>
                    <p style="text-indent: 2em">第二、客户直接浏览产品的时间仅需要几秒钟，方便快捷；</p>
                    <p style="text-indent: 2em">第三、沟通便捷，客户如果对某种产品感兴趣，可以在网上和商家直接沟通，更可以看到如实拍摄的产品图片，有效增加了成交的机率。</p>
                    <img :src="'./img/about_03.jpg'" style="float: right; margin-left: 30px; margin-top: -10px" />
                    <p style="text-indent: 2em">另外，易诚药材通通过对网店的推广，销售模式由原来的买家辛苦找卖家，卖家辛苦找卖家，变成现在只要通过易诚药材通，全国各地各市场的中药材产品商家供需情况就可以一目了然，为商家节约了大量的人力物力，有效地推动了中药材行业向着信息化、电子商务化的迅速发展。</p>
                    <p style="text-indent: 2em">同时，易诚药材通通过全国中药材市场合作商家、中药材原产地大户、汇集最新药市快讯、行情分析、产地动态等实时资讯，所有信息免费提供给用户查看，真正做到信息全公开透明。用户也可以根据自己掌握的行情价格，自行发布药材信息，共同打造真实可信的前线信息。</p>
                </div>
            </div>

            <div v-show="type === 2">
                <h2 class="subTitle">企业定位</h2>
                <img :src="'./img/about_08.png'" class="titleIcon" />

                <div class="about_culture">
                    <img :src="'./img/about_09.png'" class="bgImage01" />
                    <div class="text01"><span>打造专业的中药材垂直电商平台</span><br />为平台用户提供一站式的供应链服务</div>
                </div>

                <h2 class="subTitle">企业愿景</h2>
                <img :src="'./img/about_10.png'" class="titleIcon" />

                <div class="about_culture">
                    <img :src="'./img/about_11.png'" class="bgImage02" />
                    <div class="text02">成为全国最具影响力的中药材行业交易平台</div>
                </div>
            </div>

            <div v-show="type === 3">
                <h2 class="subTitle">公司资质</h2>
                <img src="../../assets/img/about_12.png" class="titleIcon" />

                <div class="fileList">
                    <div class="item">
                        <span>互联网药品信息服务资格证</span>
                        <img :src="about_13" @click="showLicense(about_16)" />
                    </div>
                    <div class="item">
                        <span>易诚众联网络科技有限公司营业执照</span>
                        <img :src="about_14" @click="showLicense(about_17)" />
                    </div>
                    <div class="item">
                        <span>增值电信业务经营许可证</span>
                        <img :src="about_15" @click="showLicense(about_18)" />
                    </div>
                </div>
            </div>

            <div v-show="type === 4">
                <h2 class="subTitle">联系我们</h2>
                <img :src="'./img/about_04.jpeg'" class="titleIcon" />

                <div class="callUs">
                    <div class="item">
                        <img :src="'./img/about_05.jpg'" />
                        <span class="text">沈阳服务中心: 沈阳市大东区滂江街18号龙之梦大厦33楼</span>
                    </div>

                    <div class="item">
                        <span class="text" style="padding-left: 26px">西丰运营中心: 辽宁省铁岭市西丰县西丰工业园区</span>
                    </div>

                    <div class="item">
                        <img :src="'./img/about_06.jpg'" />
                        <span class="text">全国热线电话: <span>400-091-8879</span></span>
                    </div>
                </div>

                <div class="address">
                    <img :src="'./img/about_07.jpg'" />
                </div>
            </div>
        </div>

        <imageViewer v-if="showViewer" :on-close="closeViewer" :url-list="showImageList" />
    </div>
</template>

<script>
  const imageViewer = () => import('../../components/imageViewer');
  export default {
    name: "shopAbout",
    data () {
      return {
        type: 1,
        showViewer: false,
        showImageList: [],
        about_13: require('../../assets/img/about_13.png'),
        about_14: require('../../assets/img/about_14.png'),
        about_15: require('../../assets/img/about_15.png'),
        about_16: require('../../assets/img/about_16.png'),
        about_17: require('../../assets/img/about_17.jpg'),
        about_18: require('../../assets/img/about_18.png'),
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    methods: {
      showLicense (url) {
        this.showImageList = [url]
        this.prevOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        this.showViewer = true
      },
      closeViewer () {
        document.body.style.overflow = this.prevOverflow;
        this.showViewer = false
      },
    },
    components: {
      imageViewer
    }
  }
</script>
